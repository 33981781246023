<template>
  <div class="transmit_sub_container">
    <div class="transmittal_slip_filter adj_sur">
      <div class="track_trace_adj">
        <div class="label_element_cover">
          <label>Filter By Date</label>
          <flat-pickr class="date_for_pos_fixed" v-model="daterange" :config="configDate" placeholder="Date"
            name="birthdate" @on-close="dateChanged" @on-open="cleardatepicker"></flat-pickr>
        </div>
        <div class="label_element_cover">
          <label>Filter By Status</label>
          <select v-model="status" @change="filterStatus">
            <option value disabled="disabled" selected="selected">Select status</option>
            <option value="all">All</option>
            <option value="Requested">Requested</option>
            <option value="Issued">Issued</option>
          </select>
        </div>
        <div class="label_element_cover">
          <label>Filter By Client</label>
          <select v-model="client" @change="filterClient">
            <option value disabled="disabled" selected="selected">Select Client</option>
            <option value="all">All</option>
            <option value="kwm">KWM</option>
            <option value="base client">Base Client</option>
            <option value="personal">Personal</option>
          </select>
        </div>
      </div>
    </div>

    <loader v-if="loading" />

    <section class="print_hide" v-if="!loading && show_data && prepaid.length > 0">
      <div v-for="(data, i) in prepaid" :key="i">
        <div class="container">
          <div class="row">
            <div class="col-6 invoice-template">
              <div :ref="`item_${i}`">
                <postal-item-invoice :data="data" />
              </div>
            </div>
            <div class="col-6 option-btn-section">
              <div class="two_buttons_container">
                <p class="bt bt_black">{{ data.status }}</p>
                <a href="javascript:void(0)" class="bt_print" @click="showInvoicePrintPopup(i, data)">Print Invoice</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <paginate v-if="pages > 0" v-show="!loading" v-model="current_page" :page-count="pages"
      active-class="paginate-active" :click-handler="fetchPrepaid" prev-link-class="paginate-previous"
      next-link-class="paginate-next" :prev-text="'Prev'" :next-text="'Next'" :container-class="'pagination'"
      :no-li-surround="true"></paginate>

    <h4 v-if="!loading && show_data && prepaid.length == 0">No data found</h4>

    <Popup v-if="invoice_data" :isVisible="popups.print_invoice_popup.show"
      :title="''" @close="hideInvoicePrintPopup()" width="100%" maxWidth="750px" height="90%">
        <ActionButton @handle-click="printInvoice()" :className="['print_btn']">
        Print <i class="material-icons">print</i>
      </ActionButton>
      <postal-item-invoice :data="invoice_data" />
    </Popup>

  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";

import PostalItemInvoice from '../invoices/PostalItemInvoice.vue';
import Popup from "../components/Popup.vue";
import ActionButton from "../components/ActionButton.vue";

export default {
  name: "PostalItemInvoiceReport",
  components: {
    flatPickr,
    PostalItemInvoice,
    Popup,
    ActionButton
  },
  data() {
    return {
      daterange: moment(new Date()).format('YYYY-MM-DD'),
      status: "",
      client: "all",
      prepaid: [],
      pages: 0,
      configDate: {
        mode: "range",
        format: "d-m-Y",
        altFormat: "d-m-Y",
        altInput: true
      },
      current_page: 0,
      show_data: false,
      loading: false,
      post_data: {
        statusfilter: "",
        clientFilter: "",
        from: moment(new Date()).format('YYYY-MM-DD'),
        to: moment(new Date()).format('YYYY-MM-DD'),
        isInvoiceReport:true
      },
      popups: {
        print_invoice_popup: {
          show: false,
          title: "Print Invoice"
        }
      },
      invoice_data:null,
    };
  },
  filters: {
    formatDate(value) {
      if (!value) {
        return;
      }

      return moment(String(value)).format('DD-MM-YYYY')
    },
  },
  created() {
    this.$emit("breadCrumbData", ["Report", "Postal Item Invoice"]);
  },
  methods: {
    dateChanged(daterange) {
      this.post_data.from = moment(daterange[0]).format("YYYY-MM-DD");
      this.post_data.to = moment(daterange[1]).format("YYYY-MM-DD");
      this.fetchPrepaid();
    },
    cleardatepicker() {

      this.daterange = "";
    },
    filterStatus() {
      this.post_data.statusfilter = this.status;
      this.fetchPrepaid();
    },
    filterClient() {
      this.post_data.clientFilter = this.client;
      this.fetchPrepaid();
    },
    fetchPrepaid(page = 1) {
      this.current_page = page;
      this.show_data = true;
      this.loading = true;
      this.axios
        .post("/api/postalPrepaid/" + page + "/view", this.post_data)
        .then(response => {
          if (response.data.status == "ok") {
            this.prepaid = response.data.postalprepaid.list.map(element => {

              element.total_amount_aud = element.requested_qty * element.unit_price;
 
              if (element.requester && element.requester.branch) {

                if (element.requester.branch.name.toUpperCase().includes('KWM VIC')) {
                  element.mailroom_branch = 'Melbourne';
                }

                if (element.requester.branch.name.toUpperCase().includes('KWM NSW')) {
                  element.mailroom_branch = 'Sydney';
                }

                element.mailroom_address = element.requester.branch.address_1
              }

              return element;
            });
            this.pages = response.data.postalprepaid.pages;
          }
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
        });
    },
    showInvoicePrintPopup(i, data) {
      this.invoice_data = data;
      this.popups.print_invoice_popup.show = true;
    },
    hideInvoicePrintPopup() {
      this.invoice_data = null;
      this.popups.print_invoice_popup.show = false;
    },
    printInvoice() {
      window.print();
    }
  }
};
</script>
<style lang="scss">
.transmittal_slip_filter .track_trace_adj {
  display: flex;
}

.transmittal_slip_filter .track_trace_adj span {
  display: block;
  position: relative;

  button {
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    background: none;
    right: 20px;
    top: 5px;
    border: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;

    i {
      display: inline;
      color: #fff;
      margin: 0;
      line-height: 0;
      font-size: 24px;
    }
  }
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}

.row {
  display: flex;
  flex-wrap: wrap;
}


.col-7,
.col-6,
.col-5 {
  padding: 10px;
  text-align: center;
}

.col-7 {
  width: 58.333333%;
  /* 7/12 * 100 */
}

.col-6 {
  width: 50%;
  /* 6/12 * 100 */
}

.col-5 {
  width: 41.666667%;
  /* 5/12 * 100 */
}

.invoice-template {
  background-color: #ddd;
  padding: 10px;
}

.transmittal_slip_filter .track_trace_adj .searchtext {
  width: 260px !important;
  margin-left: 10px;
}

.option-btn-section {
  display: flex;
  align-items: center;
}

.two_buttons_container {



  .bt_print {
    display: block;
    color: #000;
    background: #06A5ED;
    width: 120px;
    height: 30px;
    line-height: 30px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 5px;
    font-weight: 400;
  }
}

.bt {
  text-align: center;
  line-height: 25px;
  border-radius: 4px;
  display: block;
  margin: 5px 0;
}

.bt_black {
  background: #063657;
  color: #fff;
}

.print_btn {
  position: absolute;
  top:0%;
}

@media screen and (max-width: 1600px) {
  .transmittal_slip_filter .track_trace_adj .searchtext {
    width: 200px !important;
    margin-left: 0;
  }
}
</style>
